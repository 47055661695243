// Copyright 2025, Imprivata, Inc.  All rights reserved.

import type React from 'react';
import Icon from '@ant-design/icons';
import { Button, type ButtonProps } from '@imprivata-cloud/components';
import copyIcon from '../../assets/icons/copy.svg?react';
import { copyToClipboard } from '../../utils/utils';
import './copyButton.less';

interface CopyButtonProps {
  textToCopy: string;
  label: string;
  icon?: React.ReactNode;
  className?: string;
  size?: ButtonProps['size'];
}

const CopyButton: React.FC<CopyButtonProps> = ({
  textToCopy,
  label,
  icon = <Icon component={copyIcon} />,
  className = '',
  size = 'small',
}) => {
  const handleCopy = () => {
    copyToClipboard(textToCopy);
  };

  return (
    <Button
      type="text"
      size={size}
      label={label}
      icon={icon}
      onClick={handleCopy}
      className={`copy-button ${className}`}
      block
    />
  );
};

export default CopyButton;
