import { useSearchParams } from 'react-router-dom';
import './Settings.less';
import ImprTab from '../../../components/tab/ImprTab';
import { Button } from '@imprivata-cloud/components';
import { useState } from 'react';
import { DialogVariant, ImprDialog } from '@imprivata-cloud/components';
import ConfigEntraID from '../config-entraID/configEntraID';

const Settings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [searchParams] = useSearchParams();
  const tenantId = searchParams.get('tenantId');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const renderButton = () => (
    <div className="tab-content">
      <p>Content of Integrations</p>
      <Button size="large" type="primary" block onClick={openModal} label={'Continue'} data-testid="continue-button" />
    </div>
  );

  const tabItems = [
    {
      key: 'integrations',
      label: 'INTEGRATIONS',
      children: <div>Content of Integrations</div>,
    },
    {
      key: 'directories',
      label: 'DIRECTORIES',
      children: <div>Content of Directories</div>,
    },
    {
      key: 'groups',
      label: 'GROUPS',
      children: <div>Content of Groups</div>,
    },
    {
      key: 'customize',
      label: 'CUSTOMIZE',
      children: renderButton(),
    },
  ];

  return (
    <div className="settings-container">
      <div className="settings-header">
        <h1>Settings</h1>
      </div>
      <div className="settings-tab-container">
        <div className="settings-tab-header">
          <ImprTab tabItems={tabItems} type="card" size="large" data-testid="settings-tabs" />
        </div>
      </div>
      {/* Modal */}
      <ImprDialog
        open={isModalOpen}
        onCancel={closeModal}
        onOk={closeModal}
        type={DialogVariant.HEADER_DUAL_BUTTON}
        width={200}
        title={'Configure Entra ID'}
        content={<ConfigEntraID tenantId={tenantId || ''} />}
      />
    </div>
  );
};

export default Settings;
