import { PurchasedProductCard } from '../../../components/landing/PurchasedProductCard/PurchasedProductCard';
import { UnpurchasedProductCard } from '../../../components/landing/UnpurchasedProductCard/UnpurchasedProductCard';
import PageFooter from '../../../components/layout/PageFooter';
import './PortalLanding.less';
import { useEffect, useState } from 'react';
import { getLandingPage } from '../../../api/portalServices';
import type { GetLandingPageResponse, PurchasedProduct } from '../../../api/types';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { AppError } from '../../../errorHandler/errors';
import { SPANS_PORTAL, tracer } from '../../../utils/tracer';

export type PortalLandingProps = {
  tenantId: string;
  workflowId?: string;
};

export const PortalLanding = ({ workflowId, tenantId }: PortalLandingProps) => {
  const { emitError } = useNotifications();
  const [data, setData] = useState<GetLandingPageResponse>();

  useEffect(() => {
    const getPortalInfo = async () => {
      return await getLandingPage(workflowId || '', tenantId);
    };
    tracer.startSpan(SPANS_PORTAL.get_portal_info);
    console.log('[PortalLanding] workflowId:', workflowId);
    getPortalInfo()
      .then((response) => {
        tracer.endSpan(SPANS_PORTAL.get_portal_info);
        setData(response);
      })
      .catch((e) => {
        tracer.endSpan(SPANS_PORTAL.get_portal_info, { error: e });
        console.error('Error fetching portal info:', e);
        emitError(new AppError(e));
      });
  }, [workflowId, tenantId, emitError]);

  return (
    <>
      <div className="content-container" data-testid="landing-page">
        <div className="section">
          <div className={'iam-product-summary-container'}>
            <div>
              <h1>Imprivata Access Management</h1>
            </div>
            <div>
              <img src={data?.orgInfo?.logoUrl} alt="" style={{ width: '100px', height: '100px' }} />
            </div>
          </div>
        </div>
        {data?.productInfo?.purchasedProducts && data?.productInfo?.purchasedProducts.length > 0 && (
          <div className="section fluid">
            {data?.productInfo?.purchasedProducts?.map((product: PurchasedProduct) => (
              <PurchasedProductCard key={product.productTag} product={product} />
            ))}
          </div>
        )}
        <div className="section fluid">
          {data?.productInfo?.nonPurchasedProducts?.map((product) => (
            <UnpurchasedProductCard
              key={product.productTag}
              productName={product.displayName}
              productLink={product.learnMoreUrl}
            />
          ))}
        </div>
      </div>
      <PageFooter />
    </>
  );
};
