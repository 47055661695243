// Copyright 2025, Imprivata, Inc.  All rights reserved.

import { InputBox } from '@imprivata-cloud/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as api from '../../../api/portalServices';
import CopyButton from '../../../components/copyButton/copyButton';
import './configEntraID.less';

const TRANSLATION_ROOT = 'portal.config-entra-id';

type ConfigEntraIDProps = {
  tenantId: string;
  workflowId?: string;
};

const ConfigEntraID = ({ tenantId }: ConfigEntraIDProps) => {
  const { t } = useTranslation();
  const [ipRanges, setIpRanges] = useState<string[]>([]);
  const [entraId, setEntraId] = useState('');

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    console.log('Tenant ID:', tenantId);
    api
      .getIpRanges(tenantId)
      .then((ranges) => {
        setIpRanges(ranges);
      })
      .catch((error) => {
        console.error('Error fetching IP ranges:', error);
      });
  }, []);

  useEffect(() => {
    const inputElement = document.getElementById('entra-id-input') as HTMLInputElement;
    inputElement?.focus();
  }, []);

  // Trim
  const handleEntraIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.trim();
    setEntraId(trimmedValue);
  };

  const formattedIpRanges = ipRanges.join('\n');

  return (
    <div className="modal-config-entra-id">
      <p className="instructions">{t(`${TRANSLATION_ROOT}.instructions`)}</p>

      {/* INPUT */}
      <div className="component-title">{t(`${TRANSLATION_ROOT}.entra-id`)}</div>
      <InputBox
        id="entra-id-input"
        autoFocus
        size="large"
        data-testid="entra-id-input"
        className={'entra-id-input'}
        value={entraId}
        onChange={handleEntraIdChange}
      />

      {/* IP Ranges */}
      <div className="component-title">{t(`${TRANSLATION_ROOT}.ip-ranges`)}</div>
      <div className="ip-ranges">
        {ipRanges.map((ip) => (
          <div key={ip} className="ip-ranges-option">
            {ip}
          </div>
        ))}
      </div>

      {/* Copying IP Ranges */}
      <CopyButton textToCopy={formattedIpRanges} label={t(`${TRANSLATION_ROOT}.copy-all`)} size="small" />
    </div>
  );
};

export default ConfigEntraID;
