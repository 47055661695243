import React from 'react';
import { Outlet } from 'react-router-dom';
import ErrorBoundary from '../errorHandler/ErrorBoundary';
import { Notifications } from '../errorHandler/context/Notifications';
import { getPathKey } from '../utils/utils';
import { PortalErrorView } from './containers/errors/PortalErrorView';

const Portal = () => {
  const pathKey = getPathKey(location.pathname);

  return (
    <ErrorBoundary fallback={<PortalErrorView />}>
      <Notifications translationRoot={'portal'} key={pathKey}>
        <Outlet />
      </Notifications>
    </ErrorBoundary>
  );
};

export default Portal;
