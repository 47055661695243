import { Tabs, type TabsProps } from 'antd';
import type { ReactNode } from 'react';
import './ImprTab.less';

export type TabItemProps = {
  label: string;
  key: string;
  children: ReactNode;
};

export interface ImprTabProps extends TabsProps {
  'data-testid': string;
  containerClassName?: string;
  tabItems: Array<TabItemProps>;
}

const ImprTab = (props: ImprTabProps) => {
  const { containerClassName, tabItems, ...restProps } = props;
  return (
    <div className={'impr-tab'}>
      <Tabs
        {...restProps}
        items={tabItems.map((_) => {
          return {
            label: _.label,
            key: _.key,
            children: _.children,
          };
        })}
      />
    </div>
  );
};

export default ImprTab;
