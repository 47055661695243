import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PortalHome from './containers/PortalHome';
import PortalLayout from './containers/layout/PortalLayout';
import Settings from './containers/settings/Settings';
import Portal from './index';

const PortalRoutes = () => {
  return (
    <Routes>
      <Route path={'/'} element={<Portal />}>
        <Route index element={<PortalHome />} />
        <Route
          path={'/settings'}
          element={
            <PortalLayout>
              <Settings />
            </PortalLayout>
          }
        />
      </Route>
    </Routes>
  );
};

export default PortalRoutes;
